import React from 'react'
import styled from '@emotion/styled'
import { colors, mq } from 'src/styles'
import Slider from 'react-slick'
import rightArrow from 'src/assets/images/right-arrow.svg'
import leftArrow from 'src/assets/images/left-arrow.svg'
// import ZoomSvg from 'src/assets/images/zoom.svg'
import Button from 'src/components/Button'
import { FiZoomIn } from 'react-icons/fi'
import { Link } from 'gatsby'

const ZoomButton = styled(Button)`
  position: absolute;
  bottom: 15px;
  left: 15px;
  z-index: 1;
  svg {
    * {
      fill: transparent;
    }
    stroke: currentColor;
  }
  ${ mq.mediumAndBelow } {
    display: none;
  }
`

const RightArrow = styled(rightArrow)`
  width: 66px;
  /* .st0, .st1 {
    stroke: ${ colors.offwhite };
  } */
`

const LeftArrow = styled(leftArrow)`
  width: 66px;
  /* .st0, .st1 {
    stroke: ${ colors.offwhite };
  } */
`

class Slideshow extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeSlide: 0,
    }
  }
  render () {
    let settings = {
      ...this.props.settings,
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <RightArrow />,
      prevArrow: <LeftArrow />,
      afterChange: current => this.setState({ activeSlide: current })
    }
    const { slides, zoom = () => { }, ids = [], showZoomButton = true, afterChange } = this.props
    const { activeSlide } = this.state
    return (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <Slider {...settings} afterChange={afterChange}>
            {slides}
          </Slider>
          {showZoomButton
            ? <ZoomButton
              size="small"
              shape="circle"
              setTheme="whiteSolid"
              icon={<FiZoomIn size="20px" />}
              onClick={() => zoom({ id: ids[activeSlide] })}
            />
            : ''}
        </div>
      </React.Fragment>
    )
  }
}

export default Slideshow
