/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import { withShopifyContext } from 'src/state/ShopifyState'

import { fbTrack } from 'src/utils/tracking'

import Button from 'src/components/Button'
import { colors, animations, mq, util, typography } from 'src/styles'
import Cart from 'src/components/Cart'
import { withCartContext, TABS } from 'src/state/CartState'
import { IoMdClose } from 'react-icons/io'
import { formatPrice } from 'src/utils/format'

const containerPadding = () => `
  ${ util.responsiveStyles('padding-left', 40, 30, 26, 20) }
  ${ util.responsiveStyles('padding-right', 40, 30, 26, 20) }
`

const containerPaddingVertical = side => `
  ${ !side || side === 'top' ? `
    ${ util.responsiveStyles('padding-top', 40, 30, 26, 20) }
  ` : `` }
  ${ !side || side === 'bottom' ? `
    ${ util.responsiveStyles('padding-bottom', 40, 30, 26, 20) }
  ` : `` }
`

const CloseButton = styled(Button)`
  cursor: pointer;
  ${ util.responsiveStyles('padding-top', 27, 17, 15, 15) }
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: -12px;
`

const Close = styled(IoMdClose)`
`

const ShadedWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  color: ${ colors.textColor };
  ${ ({ isOpen }) => !isOpen ? `
    pointer-events: none;
  ` : `` }
`

const Panel = styled.div`
  height: 100%;
  width: 500px;
  max-width: 100%;
  overflow-y: scroll;
  position: fixed;
  transition: transform ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00);
  ${ ({ isOpen }) => isOpen ? `
    transform: none;
  ` : `
    transform: translate3d(100%, 0, 0);
    pointer-events: none;
  ` }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background: ${ colors.bgColor };
`

const HeaderContainer = styled.div`
  ${ containerPadding() }
  ${ containerPaddingVertical() }
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  h6 {
    margin: 0;
  }
  hr {
    position: absolute;
    ${ util.responsiveStyles('right', 40, 30, 26, 20) }
    ${ util.responsiveStyles('left', 40, 30, 26, 20) }
    bottom: 0;
    border-color: ${ colors.textColor };
    margin: 0;
  }
`
const ContentContainer = styled.div`
  ${ containerPadding() }
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  ${ containerPaddingVertical('bottom') }
  ${ ({ empty }) => empty ? `
    color: ${ colors.lightTextColor };
    text-align: center;
    ${ typography.bodyMedium }
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  ` : `` }
`
const FooterContainer = styled.div`
  ${ containerPadding() }
  ${ containerPaddingVertical('bottom') }
  flex-grow: 0;
  flex-shrink: 0;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${ colors.green };
  transition: opacity ${ animations.slowSpeed } cubic-bezier(0.44, 0.24, 0.16, 1.00);
  ${ ({ isOpen }) => isOpen ? `
    opacity: .1;
    ${ mq.mediumAndBelow } {
      opacity: .6;
    }
  ` : `
    opacity: 0;
  ` }
`

const CheckoutButton = styled(Button)`
  color: ${ colors.offwhite };
  width: 100%;
  background: ${ colors.black };
  border-color: ${ colors.black };
  &:hover {
    color: ${ colors.white };
    background: ${ colors.green };
    border-color: ${ colors.green };
  }
`

const LineItem = styled.dl`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${ colors.textColor };
  ${ util.responsiveStyles('padding-top', 30, 24, 20, 18) }
  ${ util.responsiveStyles('padding-bottom', 30, 24, 20, 18) }
  dt {
    ${ typography.h6 }
  }
  dd {
    ${ typography.body }
    font-weight: 500;
    b {
      ${ typography.bodyLarge };
      font-weight: 500;
    }
  }
`

const Drawer = ({
  cartContext: {
    isOpen,
    tab,
    toggleCart,
  },
  shopifyContext: {
    cart,
  }
}) => {
  const [contentIds, setContentIds] = useState([])
  const numberOfItemsInCart = (cart && cart.lineItems && (cart.lineItems.length > 0))
    ? cart.lineItems.length
    : null

  useEffect(() => {
    if (numberOfItemsInCart) {
      let variantIdsArray = []
      cart?.lineItems?.forEach(item => {
        const variantId = item?.variant?.id?.replace('gid://shopify/ProductVariant/', '')
        variantIdsArray.push(variantId)
      })
      setContentIds(variantIdsArray)
    }
  }, numberOfItemsInCart)

  return (
    <ShadedWrapper isOpen={isOpen}>
      <Overlay onClick={toggleCart} isOpen={isOpen} />
      <Panel
        isOpen={isOpen}
        colGap="0"
        rowGap="0"
      >
        <InnerWrapper>
          {tab === TABS.CART && (
            <Fragment>
              <HeaderContainer>
                <h6>Your Cart</h6>
                <CloseButton
                  onClick={toggleCart}
                  shape="circle"
                  size="small"
                  setTheme="transparent"
                  icon={<Close size={26} />}
                />
                {numberOfItemsInCart && (<hr/>)}
              </HeaderContainer>
              <ContentContainer empty={!numberOfItemsInCart}>
                <Cart />
              </ContentContainer>
              <FooterContainer>
                {numberOfItemsInCart && (
                  <LineItem>
                    <dt>Subtotal</dt>
                    <dd><b>{formatPrice(cart.subtotalPrice.amount)}</b></dd>
                  </LineItem>
                )}
                <Grid small="[1]" rowGap="12px">
                  {numberOfItemsInCart && (
                    <div onClick={() => {
                      fbTrack('track', 'InitiateCheckout', {
                        num_items: numberOfItemsInCart,
                        content_ids: contentIds
                      })
                    }}>
                      <CheckoutButton external={true} to={cart && cart.webUrl}>Checkout</CheckoutButton>
                    </div>
                  )}
                  <Button onClick={toggleCart}>Continue Shopping</Button>
                </Grid>
              </FooterContainer>
            </Fragment>
          )}
        </InnerWrapper>
      </Panel>
    </ShadedWrapper>
  )
}

export default withShopifyContext(withCartContext(Drawer))
