import React, { Component, Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { withShopifyContext } from 'src/state/ShopifyState'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Button from 'src/components/Button'
import { colors, typography, animations, mq, util } from 'src/styles'
import Image from 'src/components/Image'

const H4 = styled.div`
  ${ typography.h3Alt }
  margin-bottom: -0.4em;
`

const Text = styled.div`
	p {
    text-align: justify;
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
    letter-spacing: 0.62px;
	}
`

const TextSection = styled.div`
  
  ${ mq.mediumAndBelow } {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ${ ({ itemNumber }) => itemNumber !== 0 ? `
    ${ mq.largeAndUp } {
      padding-top: 16px;
      border-top: 2px solid ${ colors.textColor };
    }
  ` : '' }

`

const ImageDouble = styled.div`  
  background-image: url(${ ({ image }) => image });
  background-position: center;
  background-size: 350% auto;
  filter: grayscale(100%);

  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${ ({ backgroundImage }) => backgroundImage });
    background-size: 400px 400px;
  }
  
`

const FeaturedArticleSection = styled(Section)`
  ${ ({ itemNumber }) => itemNumber === 0 ? `
      padding-top: 20px !important;
  ` : '' }

  ${ mq.largeAndUp } {
    margin: 0 30px;
  }
`

export default ({
  id,
  slug,
  title,
  previewImage,
  previewText,
  prevTheme,
  nextTheme,
  articleIndex,
  padded,
  index
}

) => {
  const articleQuery = useStaticQuery(
    graphql`
      query {
        imgTexture: file(relativePath:{eq: "images/img-texture.png"}) {
          publicURL
        }
      }
    `
  )

  const imgTexture = articleQuery.imgTexture.publicURL

  return (
    <FeaturedArticleSection
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      padded={padded}
      itemNumber={index}
    >
      <Grid
        small='1 [12] 1'
        large='[3] [2]'
        larger='[7] [13] [7]'
        gridDirection={articleIndex % 2 ? 'ltr' : 'rtl'}
        colGap="20px"
      >
        <ResponsiveComponent small={
          <span></span>
        } larger={
          <ImageDouble backgroundImage={imgTexture} image={previewImage?.image?.fluid?.src} />
        } />

        <div>
          {previewImage && (
            <Image {...previewImage} />
          )}
        </div>
        <TextSection itemNumber={index}>
          <Grid
            small="[14]"
            // small="1 [12] 1"
            medium="[14]"
            large="[14]"
            colGap="0"
            rowGap="15px"
          >
            <div>
              <H4>{title}</H4>
            </div>
            <div>
              {previewText && <Text textSize='bodySmall'><ContentfulRichText richText={previewText.json} /></Text>}
            </div>
            <div>
              <Button
                setTheme='black'
                label='Read More'
                to={'/the-post/' + slug}
                name={'Read ' + title}
              />
            </div>
          </Grid>
        </TextSection>
      </Grid>
    </FeaturedArticleSection>
  )
}
