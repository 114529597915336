import React from 'react'
import styled from '@emotion/styled'
import Collapse from 'src/components/Collapse'
import TextLockup from 'src/components/TextLockup'
import { typography, util, animations } from 'src/styles'
import { MdKeyboardArrowDown } from 'react-icons/md'

const Wrapper = styled.div`
	text-align: left;
`

const CollapseItem = styled(Collapse)`
	border-top: 1px solid currentcolor;
	${ ({ lastItem }) => lastItem && `
		border-bottom: 1px solid currentcolor;
	` }
`

const ItemHeader = styled.div`
	text-align: left;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	${ util.responsiveStyles('padding-top', 20, 16, 14, 12) }
	${ util.responsiveStyles('padding-bottom', 20, 16, 14, 12) }
	p {
		margin: 0;
		${ typography.h6 }
	}
	.open & {
		svg {
			transform: rotate(-180deg);
		}
	}
	svg {
		transition: transform ${ animations.mediumSpeed } ease-in-out;
		flex-grow: 0;
		flex-shrink: 0;
		display: block;
		margin-right: -3px;
	}
`

const ItemContent = styled.div`
	${ util.responsiveStyles('padding-bottom', 20, 16, 14, 12) }
`

const Accordion = ({ className, items, textSize }) => (
  <Wrapper className={className}>
  	{items.map((item, index) => (
			<CollapseItem
				lastItem={index + 1 === items.length}
				title={<ItemHeader><p>{item.title}</p><MdKeyboardArrowDown size="20px" /></ItemHeader>}
			>
	  		<ItemContent><TextLockup text={item.text} textSize={textSize} /></ItemContent>
	  	</CollapseItem>
		))}
  </Wrapper>
)

export default Accordion
