import React, { Component } from 'react'
import styled from '@emotion/styled'
import { subscribe } from 'klaviyo-subscribe'

import { fbTrack } from 'src/utils/tracking'

import { darken, rgba } from 'polished'
import { colors, typography, animations, util, mq } from 'src/styles'
import ConditionalRender from 'src/components/ConditionalRender'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import MaterialIcon from 'src/components/MaterialIcon'
import { isEmoji, validateEmail } from 'src/utils/validations'
import { footerThemes } from 'src/styles/themes'
import { MdArrowForward, MdCheck } from 'react-icons/md'

const InputWrap = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
  color: inherit;
	${ typography.bodyMedium }
  font-weight: 500;
`

const StyledInput = styled(Input)`
  margin-top: 10px;
  color: inherit;
  input {
    color: inherit;
    border-color: currentcolor !important;
  }
  label.focused {
    color: inherit;
  }
  ${ mq.mediumAndUp } {
    margin-top: 0;
    input {
      border-left: none;
      border-right: none;
      border-top: none;
      padding-left: 0;
    }
    label {
      left: 0;
      padding-left: 0;
      margin-left: 0;
    }
  }
`

const InputIcon = styled.div`
`

const Wrapper = styled.div`
  position: relative;
  color: inherit;
`

const ButtonStyled = styled(Button)`
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -15px;
  background: transparent;
  border-color: transparent;
  color: inherit;
  ${ mq.mediumAndUp } {
    right: -13px;
    margin-top: -20px;
  }
  &:hover {
    background: transparent;
    border-color: transparent;
    color: inherit;
    opacity: .5;
  }
  ${ ({ disabled }) => disabled ? `
    background: transparent !important;
    border-color: transparent !important;
    color: inherit !important;
    opacity: .5 !important;
  ` : `` }
`

class FooterNewsletterSignup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      focused: false,
      hasValue: false,
      email: '',
      success: null,
      emailValid: true,
    }
  }

  renderIcon = (icon, size, iconPosition, footerTheme) => {
    let renderedIcon = false
    let isEmojiIcon = isEmoji(icon)
    if (isEmojiIcon) {
      renderedIcon = <InputIcon size={size} iconPosition={iconPosition} footerTheme={footerTheme} emojiIcon>{icon}</InputIcon>
    } else if (typeof icon === 'string') {
      renderedIcon = <InputIcon size={size} iconPosition={iconPosition} footerTheme={footerTheme}><MaterialIcon size={this.props.size === 'tiny' && '18px'}>{icon}</MaterialIcon></InputIcon>
    } else {
      renderedIcon = <InputIcon size={size} iconPosition={iconPosition} footerTheme={footerTheme}>{icon}</InputIcon>
    }
    return renderedIcon
  }

  setFocus = status => {
    this.setState({ focused: status })
  }

  setEmail = x => {
    const email = x && x.target && x.target.value
    const emailValid = validateEmail(email)
    this.setState({ email, emailValid })
  }

  submitEmail = (additionalData = null, listId) => {
    if (!listId) {
      listId = process.env.GATSBY_KLAVIYO_LIST_ID
    }
    const { email } = this.state
    fbTrack('track', 'Lead')

    subscribe(listId, email, additionalData
      // { any optional traits }
    ).then(response => {
      if (response && response.success) {
        this.setState({ success: true })
      } else {
        this.setState({ success: false })
      }
    })
  }

  render () {
    const {
      type,
      icon,
      iconPosition,
      loading,
      error,
      success,
      disabled,
      onClick,
      footerTheme,
      className,
      size = 'small',
      spellcheck,
      name,
      additionalData,
      listId
    } = this.props

    const { focused, email } = this.state

    return (
      <Wrapper>
        <form onSubmit={e => { e.preventDefault(); this.submitEmail(additionalData, listId) }}>
          <InputWrap className={className} footerTheme={footerTheme}>
            <StyledInput
              label="Enter Email"
              type={type}
              icon={icon}
              iconPosition={iconPosition}
              loading={loading}
              error={error}
              success={success}
              disabled={disabled}
              onClick={onClick}
              size={size}
              onFocus={() => this.setFocus(true)}
              onBlur={() => this.setFocus(false)} // needs work
              onChange={x => this.setEmail(x)}
              value={email}
              name={name}
              spellCheck={spellcheck}
            />
          </InputWrap>

          <ButtonStyled
            disabled={!this.state.emailValid || this.state.success}
            onClick={() => this.submitEmail(additionalData, listId)}
            shape="circle"
            size="small"
            title="Subscribe to newsletter"
            setTheme="transparent"
            icon={this.state.success ? (
              <MdCheck size={20} />
            ) : (
                <MdArrowForward size={20} />
              )}
          />
        </form>
      </Wrapper>
    )
  }
}

FooterNewsletterSignup.defaultProps = {
  type: 'text',
  iconPosition: 'left',
  footerTheme: 'red',
  spellcheck: false,
  onChange: () => { }
}

export default FooterNewsletterSignup
