import React, { useState } from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Link from 'src/components/Link'
import { fbTrack } from 'src/utils/tracking'
import OptionValues from 'src/components/OptionValues'
import { colors, typography, mq, animations, util } from 'src/styles'
import { withShopifyContext } from 'src/state/ShopifyState'
import { withCartContext, TABS } from 'src/state/CartState'
import { useStaticQuery, graphql } from 'gatsby'
import { formatPrice } from 'src/utils/format'

const HoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
  transition: opacity ${ animations.mediumSpeed } ease-in-out;
  ${ mq.mediumAndBelow } {
    display: none;
  }
`

const QuickAddLabel = styled.div`
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  .label,
  .options {
    transition: opacity ${ animations.mediumSpeed } ease-in-out;
  }
  .label {
    padding-bottom: 2px;
  }
  .options {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`

const QuickAddButton = styled.div`
  height: 50px;
  z-index: 5;
  background: ${ colors.white };
  ${ typography.buttonStyle }
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: block;
  width: calc(100% - 20px);
  color: ${ colors.lightTextColor };
  transform: translate3d(0, 10px, 0);
  transition: color ${ animations.mediumSpeed } ease-in-out,
              background ${ animations.mediumSpeed } ease-in-out,
              transform ${ animations.mediumSpeed } ease-in-out;
  &:hover {
    background: ${ colors.white };
    color: ${ colors.textColor };
    ${ ({ disabled }) => disabled ? `
      pointer-events: none;
      cursor: pointer;
      * {
        cursor: pointer;
        pointer-events: none;
      }
    ` : `` }
    ${ QuickAddLabel } {
      .label {
        opacity: 0;
      }
      .options {
        opacity: 1;
      }
    }
  }
`

const ProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  img {
    transition: opacity 1s ease-in-out !important;
  }
  &:hover {
    ${ HoverImage } {
      opacity: 1;
    }
    ${ QuickAddButton } {
      transform: none;
    }
  }
`

const TitlePriceRow = styled.div`
  ${ typography.bodySmall }
  padding: 8px 0 0 0;
  width: 100%;
  ${ mq.mediumAndUp } {
    display: flex;
    justify-content: space-between;
  }
`

const Title = styled.p`
  ${ typography.bodySmall }
  font-weight: 500;
  margin: 0;
  white-space: normal;
`

const Price = styled.p`
  ${ typography.bodySmall }
  margin: 0;
  white-space: normal;
  color: ${ colors.lightTextColor };
`

const ProductImage = styled.div`
  position: relative;
  background: ${ colors.lightGrey };
`

const OptionValuesStyled = styled(OptionValues)`
  color: ${ colors.black };
`

const ImgLinkWrapper = styled(Link)`

`

const ThumbnailIBadge = styled.div`
  position: absolute;
  z-index: 3;
  pointer-events: none;
  top: 10px;
  left: 10px;
  display: inline-block;
  vertical-align: top;
  padding: .8em;
  ${ typography.smallCaps }
  ${ util.responsiveStyles('font-size', 10, 10, 9, 8) }
  line-height: 1em;
  color: ${ colors.bgColor };
  background: ${ colors.textColor };
`

const ProductPreview = ({
  shopifyContext = {},
  cartContext = {},
  product = {},
  className
}) => {
  const { allContentfulProduct } = useStaticQuery(
    graphql`
      query {
        allContentfulProduct {
          edges {
            node {
              status
              shopifyProductHandle
            }
          }
        }
      }
    `
  )
  const [addToCartDisabled, setAddToCartDisabled] = useState(false)
  let contentfulProduct = product?.handle && allContentfulProduct?.edges[0] && allContentfulProduct?.edges
    .find(x => x?.node?.shopifyProductHandle === product.handle)?.node

  const handleSelectValue = (option, value) => {
    if (addToCartDisabled) return ''
    const { addLineItemToCart } = shopifyContext
    const { toggleCart } = cartContext
    setAddToCartDisabled(true)

    const productId = product.id.replace('gid://shopify/Product/', '')

    const { variants } = product
    const selectedVariant = variants.edges.find(({ node: { selectedOptions } }) => (
      selectedOptions.find(x => x.value === value)
    ))
    if (selectedVariant && selectedVariant.node && selectedVariant.node.availableForSale) {
      addLineItemToCart({ variantId: selectedVariant.node.id })
        .then(() => setAddToCartDisabled(false))
        .then(() => toggleCart(TABS.CART))
        .then(() => {
          fbTrack('track', 'AddToCart', {
            currency: 'USD',
            content_type: 'product_group',
            item_group_id: productId,
            content_ids: selectedVariant.node.id
          })
        })
    }
  }

  const checkIfAvailable = (option, value) => {
    const { variants } = product
    const selectedVariant = variants && variants.edges.find(({ node: { selectedOptions } }) => (
      selectedOptions.find(x => x.value === value)
    ))
    return (selectedVariant && selectedVariant.node.availableForSale)
  }

  const getResizedImage = image => {
    const resizeShopImage = shopifyContext.resizeShopImage
    return resizeShopImage(image, { maxWidth: 750, maxHeight: 750 })
  }

  const getSrcSet = image => {
    const resizeShopImage = shopifyContext.resizeShopImage
    const small = resizeShopImage(image, { maxWidth: 750, maxHeight: 750 })
    const large = resizeShopImage(image, { maxWidth: 1000, maxHeight: 1000 })
    return (small + ' 750w, ' + large + ' 1000w')
  }

  const images = product.images && product.images.edges.map(x => x.node)

  let price = product && product.variants && product.variants.edges[0].node.price.amount
  
  price = formatPrice(price)
  
  const allSoldOut = !product.options.find(option => option.name === 'Size').values.find(x => checkIfAvailable(null, (x.value || x)))

  const quickAddDisabled = contentfulProduct?.status === 'coming-soon'

  return (
    <div className={className}>
      <ProductWrapper>
        <ProductImage>
          {contentfulProduct?.status === 'coming-soon' && (<ThumbnailIBadge>Coming Soon</ThumbnailIBadge>)}
          {images && images[0] && images[0].url ? (
            <ImgLinkWrapper to={`/shop/${ product.handle }`} title={product.title}>
              <Img
                fluid={{
                  aspectRatio: 0.75,
                  src: getResizedImage(images[0]),
                  srcSet: getSrcSet(images[0]),
                  sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 43vw, 12vw'
                }}
              />
            </ImgLinkWrapper>
          ) : ''}
          <HoverImage>
            {images && images[1] && images[1].url ? (
              <ImgLinkWrapper to={`/shop/${ product.handle }`} title={product.title}>
                <Img
                  fluid={{
                    aspectRatio: 0.75,
                    src: getResizedImage(images[1]),
                    srcSet: getSrcSet(images[1]),
                    sizes: '(max-width: ' + mq.mediumBreakpoint + 'px) 43vw, 12vw'
                  }}
                />
              </ImgLinkWrapper>
            ) : ''}
            <QuickAddButton setTheme="whiteSolid" disabled={quickAddDisabled}>
              <QuickAddLabel>
                {quickAddDisabled ? (
                  <div style={{ color: colors.lightTextColor }}>View Details</div>
                ) : (
                  allSoldOut ? (
                    <div style={{ color: colors.lightTextColor }}>Sold Out</div>
                  ) : (
                    <React.Fragment>
                      <div className="label">+ Quick Add</div>
                      <div className="options">
                        {product.options && product.options.map((option, index) => {
                          if (option.name !== 'Size') return ''
                          return < OptionValuesStyled
                            key={option.id + index + 'option_details_quick_row'}
                            option={option}
                            selectValue={handleSelectValue}
                            checkIfAvailable={checkIfAvailable}
                            selected={null}
                          />
                        })}
                      </div>
                    </React.Fragment>
                  )
                )}
              </QuickAddLabel>
            </QuickAddButton>
          </HoverImage>
        </ProductImage>
        <TitlePriceRow>
          <Title>
            <div>
              {product.title}
            </div>
          </Title>
          <Price>
            {price}
          </Price>
        </TitlePriceRow>
      </ProductWrapper>
    </div>

  )
}

export default withShopifyContext(withCartContext(ProductPreview))
