import React, { Component } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import TextLockup from 'src/components/TextLockup'
import Section from 'src/components/Section'
import * as util from 'src/styles/util'
import { colors, typography, animations, mq } from 'src/styles'
import { themes } from 'src/styles/themes'
import MaterialIcon from 'src/components/MaterialIcon'

const TextContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const H2 = styled.div`
  ${ typography.h2 }
  padding-bottom: 20px;
  ${ mq.mediumAndBelow } {
    padding-top: 30px;
    padding-bottom: 15px;
  }
`

const ImageContainerControlledAspectRatio = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${ mq.extraExtraLargeAndBelow } {
    ${ util.responsiveStyles('max-height', 800, 500, 300, 200) }
  }
  ${ mq.extraExtraLargeAndUp } {
    max-height: 70vh;
    min-height: 700px;
  }
  overflow: hidden;
`

const Fabric = ({
  id,
  displayTitle,
  bodyText,
  image,
  padded,
  isFirstSection,
  className,
  theme,
  prevTheme,
  nextTheme,
}) => {
  return (
    <Section
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      padded={padded}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="1 [14] 1 [9] 1"
        gridDirection="rtl"
      >
        <ImageContainerControlledAspectRatio>
          <Image image={image} />
        </ImageContainerControlledAspectRatio>
        <TextContainer>
          <H2>
            {displayTitle}
          </H2>
          <div>
            {bodyText ? <TextLockup text={bodyText} /> : ''}
          </div>
        </TextContainer>
      </Grid>
    </Section>
  )
}

export default Fabric
