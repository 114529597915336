import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import { colors, typography, util } from 'src/styles'
import AnimateHeight from 'react-animate-height'

const timeout = 300
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const TransitionWrapper = styled(AnimateHeight)`
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
	transition: height ${ timeout }ms ${ timingFunction };
  overflow: hidden;
  z-index: 10;
  > div {
    width: 100%;
  }
`

const LinkContainer = styled.div`
`

const Dropdown = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  min-width: 200px;
  background: ${ colors.offwhite };
  color: ${ colors.black };
  ${ typography.bodySmall };
  font-weight: 500;
  letter-spacing: 0;
  padding: 0 16px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 25px;
    height: 100%;
    height: 70px;
    &:after {
      content: '';
      position: absolute;
      right: 18px;
      width: 5px;
      height: 5px;
      transform: rotate(-45deg);
      border-bottom: 2px solid;
      border-right: 2px solid;
      border-color: ${ colors.mainColor };
      top: 50%;
      margin-top: -3px;
      opacity: 0;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    }
    &:hover {
      &:after {
        opacity: 1;
        transform: rotate(-45deg) translate3d(5px, 5px, 0);
      }
    }
  }
`

const HeaderDropdown = ({ open, items, className }) => {
  return (
    <TransitionWrapper
      className={className}
      duration={timeout}
      delay={0}
      animateOpacity={false}
      height={ !open ? 0 : 'auto' }
    >
      <div>
        <Dropdown>
        	{items.map((dropdownLink, index) => (
            <LinkContainer key={dropdownLink.to + index}>
              <Link to={dropdownLink.to}>{dropdownLink.label}</Link>
            </LinkContainer>
          ))}
        </Dropdown>
      </div>
    </TransitionWrapper>
  )
}

export default HeaderDropdown
