import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import ContentfulRichText from 'src/components/ContentfulRichText'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Header from 'src/components/Header'
import Collapse from 'src/components/Collapse'
import { colors, typography, util } from 'src/styles'
import { Transition } from 'react-transition-group'
// import { FaInstagram, FaTwitter, FaFacebookF } from 'react-icons/fa'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { withCartContext } from 'src/state/CartState'

const timeout = 1000
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled.div`
	position: fixed;
	transform: translate3d(0, 0, 0);
	pointer-events: none;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 11;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	background: ${ colors.offwhite };
	${ util.responsiveStyles('padding-top', 113, 105, 90, 50) }
	transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
	// transform: translate3d(110%, 0, 0);
	opacity: 0;
	${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
		// transform: translate3d(110%, 0, 0);
		opacity: 0;
	` : `` }
	${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
		// transform: none;
		opacity: 1;
	` : `` }

`

const CollapsedNav = styled(Collapse)`
	${ ({ lastItem }) => lastItem ? `
		border-bottom: 1px solid ${ colors.hrColor };
	` : `` }
`

const MobileNavLink = styled(Link)`
	${ typography.bodyLarge }
	font-weight: 600;
	display: block;
	padding: 2px 0;
	color: ${ colors.textColor };
	display: flex;
	text-align: left;
	border-top: 1px solid ${ colors.hrColor };
	height: 50px;
	align-items: center;
	span {
		flex-grow: 1;
		display: block;
	}
	svg {
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
	}
`

const MainSection = styled.div`
	flex-grow: 1;
	flex-shrink: 0;
	display: flex;
	align-items: flex-start;
	padding: 5vw 0 0;
	pointer-events: all;
`

const SubLinks = styled.ul`
	margin-bottom: 20px;
	list-style: none;
	padding-left: 0;
	li {
		a {
			display: block;
			${ typography.bodyMedium }
			padding: 3px 0;
		}
		&:first-of-type {
			a {
				padding-top: 0;
			}
		}
		&:last-of-type {
			a {
				padding-bottom: 0;
			}
		}
	}
`

const MobileMenu = ({
	open,
	toggleMobileMenu,
	navLinks,
	footerColumn1,
	footerColumn2,
	cartContext: {
    mobileMenuIsOpen
  },
}) => {
  return (
    <Transition
      in={mobileMenuIsOpen}
      timeout={{
        enter: 1,
        exit: timeout
      }}
      unmountOnExit
      mountOnEnter
    >
      {transitionStatus => (
        <Wrapper transitionStatus={transitionStatus}>
        	<Header/>
          <MainSection>
            <Grid small="1 [12] 1">
              <ScrollEntrance>
                {navLinks.map((link, index) => (
                  <div>
                    {link.dropdownLinks ? (
                      <CollapsedNav
                      	lastItem={navLinks.length === index + 1}
												icon={false}
												title={
		                      <MobileNavLink to={link.to} title={'Navigate to ' + link.label + ' page'}>
		                      	<span onClick={toggleMobileMenu}>{link.label}</span>
		                      	<MdKeyboardArrowDown size="24px" />
		                      </MobileNavLink>
												}
											>
			                  <div>
			                    <SubLinks>
		                        {link.dropdownLinks.map((dropdownLink, index) => (
															<li>
		                            <Link to={dropdownLink.to} title={'Navigate to ' + dropdownLink.label + ' page'}>{dropdownLink.label}</Link>
		                          </li>
														))}
		                      </SubLinks>
			                  </div>
			                </CollapsedNav>
                    ) : (
											<MobileNavLink to={link.to}><span onClick={toggleMobileMenu}>{link.label}</span></MobileNavLink>
                    )}
                  </div>
                ))}
              </ScrollEntrance>
            </Grid>
          </MainSection>
          {/* <BottomSection>
            <Grid small="1 [12] 1" rowGap="3vw">
              {footerColumn1.json && (
                <ScrollEntrance delay={navLinks.length + 1}>
                  <ContentfulRichText richText={footerColumn1.json} />
                </ScrollEntrance>
              )}
              {footerColumn2.json && (
                <ScrollEntrance delay={navLinks.length + 2}>
                  <ContentfulRichText richText={footerColumn2.json} />
                </ScrollEntrance>
              )}
            </Grid>
          </BottomSection> */}
        </Wrapper>
      )}
    </Transition>
  )
}

export default withCartContext(MobileMenu)
