/* eslint-disable camelcase */
import React from 'react'
import styled from '@emotion/styled'
import { colors, typography, animations, util, mq } from 'src/styles'
import { Link } from 'gatsby'

const UnstyledButton = styled.button`
  display: block;
	appearance: none;
	line-height: unset;
  outline: none;
  width: 100%;
  border: none;
  background: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-weight: inherit;
  padding: 0;
  margin: 0;
  border-radius: unset;
  box-shadow: unset;
  background: unset;
  color: inherit;
  height: unset;
  cursor: pointer;
  transition: none;
`

const CircleSelect = styled(UnstyledButton)`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
  border: 1px solid transparent;
  transition: border-color .3s ease-in-out;
  margin-right: 4px;
  &:disabled {
    color: ${ colors.lightGrey };
    cursor: default;
    pointer-events: none;
    &:hover {
      border-color: transparent;
    }
  }
  &:first-of-type {
    margin-left: -3px;
  }
  &:hover {
    border-color: ${ colors.hrColor };
  }
  img {
    height: 26px;
    width: 26px;
    border-radius: 50%;
    ${ ({ backgroundSrc }) => backgroundSrc ? `background: url("${ backgroundSrc }");` : '' }
  }
  span {
    ${ typography.h6 };
    letter-spacing: 0;
    font-weight: 500;
    padding-bottom: 1px;
    ${ mq.mediumAndBelow } {
      ${ util.responsiveStyles('font-size', 14, 14, 14, 14) }
    }
  }
  &.selected {
    border-color: ${ colors.black };
  }
  ${ mq.mediumAndBelow } {
    height: 40px;
    width: 40px;
    img {
      height: 34px;
      width: 34px;
    }
  }
`

const OptionValues = ({ option, swatches, selected, selectValue, allSoldOut, checkIfAvailable }) => {
  if (option.name === 'Color') {
    return (
      <React.Fragment>
        {swatches && swatches.map(({ id, attr_2_name, handle, image }) => {
          let colorSelected = selected === attr_2_name
          if (!swatches || (swatches.length === 1)) colorSelected = true
          return (
            <CircleSelect
              key={id}
              className={colorSelected ? 'selected' : ''}
              backgroundSrc={image && image.img}
            >
              <Link to={handle && `/shop/${ handle }`}>
                <span />
                <img src={image && image.img} />
              </Link>
            </CircleSelect>
          )
        })}
      </React.Fragment>
    )
  } else if (option.name === 'Size') {
    return (
      <React.Fragment>
        {option.values.map(x => {
            const value = x.value || x
            const disabled = !checkIfAvailable(option, value)
            return (
              <CircleSelect
                key={value}
                onClick={() => selectValue(option, value)}
                className={selected === value ? 'selected' : ''}
                disabled={disabled}
              >
                <span>
                  {value}
                </span>
              </CircleSelect>
            )
          })}
      </React.Fragment>
    )
  } else return <div>unsupported option</div>
}
export default OptionValues
