import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import { graphql, StaticQuery } from 'gatsby'
import { colors, typography, animations, mq, util } from 'src/styles'
import FabricOverlay from 'src/components/FabricOverlay'
import { withShopifyContext } from 'src/state/ShopifyState'
import { Transition } from 'react-transition-group'

const timeout = 500
const timingFunction = 'ease-in-out'

const UnstyledButton = styled.button`
  font-size: inherit;
  display: block;
  background: inherit;
  overflow: hidden;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
  appearance: none;
  position: relative;
  ${ typography.body }
  &:hover {
    .gatsby-image-wrapper {
      &:after {
        opacity: .25;
      }
    }
  }
`

const SectionTitle = styled.div`
  ${ typography.h1 }
  padding-bottom: 40px;
  ${ mq.mediumAndBelow } {
    padding-bottom: 25px;
  }
`

const FabricTitle = styled.div`
  ${ typography.h6 }
  ${ util.responsiveStyles('font-size', 12, 12, 11, 10) }
  line-height: 1.2em;
  padding-top: 1em;
  ${ ({ overlay }) => overlay && `
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    color: ${ colors.bgColor };
  ` }
`

const ImageWrapper = styled.div`
  .gatsby-image-wrapper {
    background: ${ colors.lightGrey };
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${ colors.textColor };
      opacity: 0;
      transition: opacity ${ animations.mediumSpeed } ease-in-out;
    }
  }
`

const StyledFabricOverlay = styled(FabricOverlay)`
  transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
  opacity: 0;
  ${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
    opacity: 0;
  ` : `` }
  ${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
    opacity: 1;
  ` : `` }
`

class FabricsList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: [],
      selectedFabric: null
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  escFunction = e => {
    if (e && e.keyCode === 27) { // escape key maps to keycode `27`
      this.toggleFabric()
    }
  }

  toggleFabric = fabric => {
    if (!fabric) {
      this.setState({ selectedFabric: null })
    } else {
      this.setState({ selectedFabric: 'loading' })
    }
    if (fabric) {
      const { shopifyContext } = this.props
      const allContentfulFabric = this.props.data && this.props.data.allContentfulFabric && this.props.data.allContentfulFabric.edges.map(x => x.node)
      const thisContentfulFabric = allContentfulFabric && allContentfulFabric.find(x => x.id === fabric.id)
      if (!thisContentfulFabric) return this.setState({ selectedFabric: null })
      const { productHandles } = thisContentfulFabric
      const { fetchProductByHandle } = shopifyContext
      if (productHandles && productHandles.length > 0) {
        const promises = productHandles.map(productHandle => fetchProductByHandle({ productHandle }))
        Promise.all(promises)
          // combine data
          .then(unfilteredProducts => {
            const products = unfilteredProducts.filter(x => x) // filter out null
            if (products) {
              this.setState({ products, selectedFabric: { ...fabric, ...thisContentfulFabric } })
            } else {
              this.setState({ products: null, selectedFabric: { ...fabric, ...thisContentfulFabric } })
            }
          })
      } else {
        this.setState({ products: null, selectedFabric: { ...fabric, ...thisContentfulFabric } })
      }
    }
  }

  render () {
    const {
      id,
      padded,
      isFirstSection,
      className,
      theme,
      prevTheme,
      nextTheme,
      displayTitle,
      fabrics,
    } = this.props
    const { selectedFabric } = this.state
    return (
      <React.Fragment>
        <Transition
          in={selectedFabric && selectedFabric !== null}
          timeout={{
            enter: 1,
            exit: timeout
          }}
        >
          {transitionStatus => (
            <StyledFabricOverlay
              key={selectedFabric + '-modal'}
              transitionStatus={transitionStatus}
              toggleFabric={this.toggleFabric}
              selectedFabric={selectedFabric}
              open={selectedFabric !== null} products={this.state.products}
            />
          )}
        </Transition>

        <Section
          className={className}
          setTheme={theme}
          prevTheme={prevTheme}
          nextTheme={nextTheme}
          padded={padded}
          isFirstSection={isFirstSection}
        >
          <Grid
            small="1 [12] 1"
            medium="1 [12] 1"
            large="1 [24] 1"
          >
            <SectionTitle>
              {displayTitle}
            </SectionTitle>
          </Grid>
          <Grid
            vAlign="top"
            rowGap={['7vw', '3.8vw', '3.5vw']}
            colGap={['14px', '16px', '20px']}
            small="1 [6] [6] 1"
            medium="1 [4] [4] [4] 1"
            large="1 [6] [6] [6] [6] 1"
          >
            {fabrics && fabrics.map((fabric, index) => {
              if (fabric.image.fluid) {
                fabric.image.fluid.aspectRatio = 1
                fabric.image.fluid.sizes = '(max-width: ' + mq.mediumBreakpoint + 'px) 45vw, (max-width: ' + mq.largeBreakpoint + 'px) 33vw, 25vw'
              }
              return (
                <UnstyledButton key={fabric + index} onClick={() => this.toggleFabric(fabric)}>
                  <ImageWrapper>
                    <Img fluid={fabric.image && fabric.image.fluid} />
                  </ImageWrapper>
                  <FabricTitle overlay={fabric.thumbnailTextPlacement === 'Overlay'}>
                    <div>{fabric.displayTitle}</div>
                  </FabricTitle>
                </UnstyledButton>
              )
            })}
          </Grid>
        </Section>
      </React.Fragment>
    )
  }
}

const ComponentWithQuery = props => (
  <StaticQuery
    query={graphql`
      query FabricsListContext {
        allContentfulFabric {
          edges {
            node {
              __typename
              id
              displayTitle
              textColor
              backgroundColor
              thumbnailTextPlacement
              imageOverlay
              text {
                json
              }
              image {
                fluid(maxWidth: 1500, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              productHandles
              associatedShopifyCollectionHandle
            }
          }
        }
      }
    `}
    render={data => <FabricsList data={data} {...props} />}
  />
)

export default withShopifyContext(ComponentWithQuery)
