import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import ShoppableImage from 'src/components/ShoppableImage'

const WideMediaSection = styled(Section)`
  ${ ({ invertHeader }) => invertHeader ? `
    position: relative;
    z-index: 10;
  ` : `` }
`

const Block = styled.div`
	display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  ${ ({ background }) => background ? `
    height: 100%;
    overflow: hidden;
    z-index: 1;
    ` : '' }
  height: ${ ({ setHeight }) => setHeight };
  ${ ({ content }) => content ? 'z-index: 3; position: absolute; top: 0;' : '' }
`

class ShoppableImageSection extends React.Component {
  render () {
    const {
      width,
      theme,
      prevTheme,
      nextTheme,
      id,
      image,
      imageWidth,
      shopButtonPlacement,
      productHandles,
    } = this.props

    const fullWidth = width === 'fullWidth'

    return (
      <WideMediaSection
        setTheme={theme}
        nextTheme={nextTheme}
        prevTheme={prevTheme}
        padded={!fullWidth}
      >
        <Block>
          <Grid small={fullWidth ? '[1]' : '1 [12] 1'} large={fullWidth ? '[1]' : '1 [24] 1'}>
            <ShoppableImage
              id={id}
              image={image}
              imageWidth={imageWidth}
              shopButtonPlacement={shopButtonPlacement}
              productHandles={productHandles}
            />
          </Grid>
        </Block>
      </WideMediaSection>
    )
  }
}

ShoppableImageSection.defaultProps = {
  width: 'margins',
  height: 'auto'
}

export default ShoppableImageSection
