import React from 'react'

import TextSection from 'src/components/TextSection'
import Columns from 'src/components/Columns'
import WideMedia from 'src/components/WideMedia'
import FiftyFifty from 'src/components/FiftyFifty'
import ProductGrid from 'src/components/ProductGrid'
import FeaturedCollection from 'src/components/FeaturedCollection'
import ArticlePreview from 'src/components/ArticlePreview'
import ImageInBlog from 'src/components/ImageInBlog'
import LookbookPreview from 'src/components/LookbookPreview'
import ShoppableImageSection from 'src/components/ShoppableImageSection'
import Fabric from 'src/components/Fabric'
import FabricsList from 'src/components/FabricsList'
import ContactForm from 'src/components/ContactForm'

const componentMap = {
	ContentfulTextSection: TextSection,
	ContentfulColumns: Columns,
	ContentfulFiftyFifty: FiftyFifty,
  ContentfulWideMedia: WideMedia,
  ContentfulProductGrid: ProductGrid,
  ContentfulFeaturedCollection: FeaturedCollection,
  ContentfulArticle: ArticlePreview,
  ContentfulImage: ImageInBlog,
  ContentfulLookbook: LookbookPreview,
  ContentfulShoppableImage: ShoppableImageSection,
  ContentfulFabric: Fabric,
  ContentfulFabricsList: FabricsList,
  ContentfulContactForm: ContactForm
}

export default ({ item, prevTheme, nextTheme, index, isLastSection, isFirstSection, padded, articleIndex, navigation, location, className }) => {
  const Component = componentMap[item.__typename]
	return Component ? (
		<Component
			{...item}
			prevTheme={prevTheme}
			nextTheme={nextTheme}
			isLastSection={isLastSection}
			isFirstSection={isFirstSection}
      index={index}
      articleIndex={articleIndex}
      padded={padded}
      navigation={navigation}
      location={location}
      className={className}
		/>
	) : null
}
