import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import { withShopifyContext } from 'src/state/ShopifyState'
import { colors, typography, animations, mq, util } from 'src/styles'
import ProductPreview from 'src/components/ProductPreview'

const SectionTitle = styled.h3`
  margin-top: 0;
  ${ util.responsiveStyles('margin-bottom', 50, 28, 33, 26) }
`

const ProductGrid = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  title,
  isFirstSection,
  shopifyContext,
  collectionHandle,
  columnCount,
  productCount
}) => {
  const { collections } = shopifyContext
  const thisCollection = collections.find(x => x.handle === collectionHandle)
  if (!thisCollection) return ''
  if (columnCount === null) { columnCount = '3' }
  if (productCount === null) { productCount = '100' }
  return (
    <Section
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        large="1 [24] 1"
      >
        {title && (
          <SectionTitle>{title}</SectionTitle>
        )}
        <Grid
          small='[6] [6]'
          medium='[6] [6]'
          large={columnCount === '3' ? '[8] [8] [8]' : '[6] [6] [6] [6]'}
          rowGap={['7vw', '3.8vw', '3.5vw']}
          colGap={['14px', '16px', '20px']}
        >
          {(thisCollection && thisCollection.products)
            ? thisCollection.products.slice(0, productCount)
              .map((product, index) => (
                <ProductPreview
                  key={`${ thisCollection.id }_${ product.id }_${ index }`}
                  product={product}
                />
              ))
            : ''}

        </Grid>
      </Grid>
    </Section>
  )
}

export default withShopifyContext(ProductGrid)
