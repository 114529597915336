import React, { Fragment } from 'react'
import withSizes from 'react-sizes'
import { mediaQueries } from 'src/styles'

const widthToRenderer = (winWidth, small, mediumSmall, medium, large, larger) => {
	if (larger && winWidth > mediaQueries.largerBreakpoint) {
		return larger
	}
	
	if (large && winWidth > mediaQueries.largeBreakpoint) {
		return large
	}

	if (medium && winWidth > mediaQueries.mediumBreakpoint) {
		return medium
	}

	if (mediumSmall && winWidth > mediaQueries.smallBreakpoint) {
		return mediumSmall
	}

	return small || mediumSmall || medium || large || larger
}

const ResponsiveComponent = ({ winWidth, small, medium, large, larger, mediumSmall }) => (
	<Fragment>
		{widthToRenderer(
			winWidth,
			small,
			mediumSmall,
			medium,
			large,
			larger
		)}
	</Fragment>
)

const sizesToProps = ({ width, height }) => ({
	winWidth: width
})

export default withSizes(sizesToProps)(ResponsiveComponent)
