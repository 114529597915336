import React, { Component } from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.div`
	video {
		display: block;
		margin: 0;
		outline: none;
    max-width: 100%;
    height: auto;
		&:focus {
			outline: none;
		}
	}
`

class RawVideo extends Component {
	render () {
		const {
			video,
			cover,
			className,
			posterImage
		} = this.props

		if (!video || !video.file || !video.file.url) {
			return false
		}

		return (
			<Wrapper className={className} cover={cover} posterImage={posterImage?.fluid?.src}>
				<video autoPlay muted loop playsInline poster={posterImage}>
          <source src={video.file.url} type="video/mp4" />
        </video>
			</Wrapper>
		)
	}
}

export default RawVideo
