import React, { Component } from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Input from 'src/components/Input'
import Select from 'src/components/Select'
import Section from 'src/components/Section'
import { colors, typography } from 'src/styles'
import { validateEmail } from 'src/utils/validations'

const Form = styled.form`
  display: inline-block;
  vertical-align: top;
  max-width: 600px;
  width: 100%;
`

const StyledInput = styled(Input)`
  margin-bottom: 16px;
  ${ ({ type }) => type === 'hidden' ? `
    display: none;
  ` : `` }
  ${ ({ isValid }) => isValid === false ? `
    input {
      border-color: ${ colors.red };
    }
  ` : `` }
`

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`

const StyledTextArea = styled.textarea`
  appearance: none;
  outline: none;
  border: 1px solid ${ colors.textColor };
  ${ typography.body }
  display: block;
  width: 100%;
  font-weight: 500;
  padding: .8em 1em .9em;
  resize: none;
  background: transparent;
  &:hover,
  &:focus {
    border-color: ${ colors.green };
  }
  ::placeholder {
    color: ${ colors.lightTextColor };
  }
`

class ContactForm extends Component {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: '',
      firstName: '',
      lastName: '',
      email: '',
      subject: 'default',
      message: ''
    }
  }

  handleInput = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render () {
    const { className, theme, prevTheme, nextTheme } = this.props
    const { status, firstName, lastName, email, subject, message } = this.state
    const valid = firstName !== '' && lastName !== '' && email !== '' && subject !== 'default' && message !== '' && validateEmail(email)
    return (
      <Section
        className={className}
        setTheme={theme}
        prevTheme={prevTheme}
        nextTheme={nextTheme}
      >
        <Grid small='1 [12] 1' large='1 [24] 1'>
          <div style={{ textAlign: 'center' }}>
            <Form
              onSubmit={this.submitForm}
              action="https://formspree.io/mjvpwrqv"
              method="POST"
            >
              <StyledInput type="hidden" name="_replyto" value={email} />
              <StyledInput type="hidden" name="_subject" value={firstName + ' ' + lastName + '–' + subject} />
              <Grid small="[1]" large="[1] [1]" colGap="12px">
                <div>
                  <StyledInput
                    setTheme="black"
                    onChange={this.handleInput}
                    type="text"
                    name="firstName"
                    label="First Name"
                    value={firstName}
                  />
                </div>
                <div>
                  <StyledInput
                    setTheme="black"
                    onChange={this.handleInput}
                    type="text"
                    name="lastName"
                    label="Last Name"
                    value={lastName}
                  />
                </div>
              </Grid>
              <StyledSelect
                setTheme="black"
                name="subject"
                onChange={this.handleInput}
                value={subject}
                options={[
                  { label: 'General', value: 'General' },
                  { label: 'Support', value: 'Support' },
                  { label: 'Press', value: 'Press' },
                  { label: 'Sales Inquiry', value: 'Sales Inquiry' }
                ]}
              />
              <div>
                <StyledInput
                  setTheme="black"
                  onChange={this.handleInput}
                  type="email"
                  name="email"
                  label="Email"
                  value={email}
                  isValid={email !== '' ? validateEmail(email) : true}
                />
              </div>
              <div>
                <StyledTextArea
                  setTheme="black"
                  onChange={this.handleInput}
                  name="message"
                  label="Email"
                  value={message}
                  placeholder="Message"
                  rows="5"
                />
              </div>
              <div style={{ paddingTop: '16px' }}>
                <Button type="submit" disabled={!valid}>
                  {status === 'SUCCESS' ? 'Thank You' : 'Submit'}
                </Button>
              </div>
            </Form>
          </div>
        </Grid>
      </Section>
    )
  }

  submitForm (ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        this.setState({ status: 'SUCCESS' })
        setTimeout(() => {
          this.setState({
            status: '',
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: 'default'
          })
          form.reset()
        }, 1000)
      } else {
        this.setState({ status: 'ERROR' })
      }
    }
    xhr.send(data)
  }
}

export default ContactForm
