/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { rgba } from 'polished'
import { colors, animations, mq, util, typography } from 'src/styles'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import { withShopifyContext } from 'src/state/ShopifyState'
import { MdAdd, MdRemove } from 'react-icons/md'
import { formatPrice } from 'src/utils/format'

const TextWrapper = styled.div`
  max-width: 100%;
  ${ typography.bodySmall }
  display: flex;
  flex-direction: column;
`

const PriceWrapper = styled.div`
  ${ typography.bodySmall }
  text-align: right;
  color: ${ colors.lightTextColor };
`

const CartItem = styled(Grid)`
  margin-top: 16px;
`

const CartItemImage = styled(Img)`
  background: ${ colors.lightGrey };
`

const Title = styled.div`
  font-weight: 500;
`

const Description = styled.div`
  color: ${ colors.lightTextColor };
`

const UnstyledButton = styled.button`
  font-size: inherit;
  background: inherit;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  color: inherit;
  cursor: pointer;
  text-align: left;
`

const RemoveLink = styled(UnstyledButton)`
  ${ typography.h6 }
  ${ util.responsiveStyles('font-size', 11, 11, 10, 10) }
  color: ${ rgba(colors.green, 0.5) };
  transition: color ${ animations.mediumSpeed } ease-in-out, border ${ animations.mediumSpeed } ease-in-out;
  border-bottom: 1px solid transparent;
  padding-top: 4px;
  margin-left: 10px;
  &:hover {
    color: ${ colors.red };
    border-color: ${ colors.red };
  }
`

const QtyControl = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${ colors.hrColor };
  p {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    width: 3em;
    text-align: center;
  }
  ${ UnstyledButton } {
    border-right: 1px solid ${ colors.hrColor };
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    color: ${ colors.lightTextColor };
    transition: color ${ animations.mediumSpeed } ease-in-out, background ${ animations.mediumSpeed } ease-in-out;
    &:hover {
      background: ${ colors.hrColor };
      color: ${ colors.textColor };
    }
    svg {
      display: block;
    }
    &:last-of-type {
      border-right: none;
      border-left: 1px solid ${ colors.hrColor };
    }
  }
`

const OptionsRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

const Cart = ({
  shopifyContext: {
    checkoutId,
    cart,
    updateLineItemInCart,
    removeLineItemFromCart,
    resizeShopImage
  },
}) => {
  if (!(cart && cart.lineItems && (cart.lineItems.length > 0))) return 'Your shopping bag is empty.'

  const getResizedImage = (image, size = 1200) => {
    console.log(image)
    return resizeShopImage(image, { maxWidth: size, maxHeight: size })
  }

  return cart.lineItems.map(item => {
    const { id, title, quantity, variant = {} } = item
    const {
      title: variantTitle,
      image,
      price,
      priceV2 = {}
    } = (variant || item)
    const { currencyCode } = priceV2
    const priceString = (currencyCode && currencyCode === 'USD') ? `${ formatPrice(price.amount) }` : `${ price.amount } ${ currencyCode }`
    return (
      <CartItem
        key={id}
        vAlign="center"
        small="[4] [13]" // 26 units to work with
        medium="[3] [13]" // 18 units to work with
        large="[3] [13]" // 14 units to work with
        extraLarges="[2] [6]" // 8 units to deal with
        colGap="16px"
      >
        <div>
          {image &&
          <CartItemImage
            alt={(image && image.altText) || 'product variant photo'}
            fluid={{
              aspectRatio: 0.75,
              src: getResizedImage(image, 180),
              srcSet: '',
              sizes: ''
            }}
          />
          }
        </div>
        <div>
          <Grid
            small="[13] 1 [4]" // 18 units to work with
            medium="[8] 1 [3]" // 14 units to work with
            large="[5] 1 [2]" // 12 units to work with
            extraLarge="[4] 1 [1]" // 6 units to work with
            rowGap="0"
            colGap="0"
            vAlign="top"
          >
            <TextWrapper>
              <Title>{title}</Title>
              <Description>{variantTitle}</Description>
              <OptionsRow>
                <QtyControl>
                  <UnstyledButton
                    onClick={() => updateLineItemInCart({ checkoutId, id, quantity: quantity - 1 })}
                  >
                    <MdRemove size="14px"/>
                  </UnstyledButton>
                  <p>{quantity}</p>
                  <UnstyledButton
                    onClick={() => updateLineItemInCart({ checkoutId, id, quantity: quantity + 1 })}
                  >
                    <MdAdd size="14px"/>
                  </UnstyledButton>
                </QtyControl>
                <RemoveLink
                  onClick={() => removeLineItemFromCart({ checkoutId, id })}
                  style={{ color: colors.darkGrey }}
                >
                  REMOVE
                </RemoveLink>
              </OptionsRow>
            </TextWrapper>
            <PriceWrapper>
              {priceString}
            </PriceWrapper>
          </Grid>
        </div>
      </CartItem>
    )
  })
}

export default withShopifyContext(Cart)
