import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import Caption from 'src/components/Caption'
import { colors, mq } from 'src/styles'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import MobileDetect from 'mobile-detect'
import withSizes from 'react-sizes'
import Header from 'src/components/Header'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const StyledHeader = styled(Header)`
  z-index: 6;
  pointer-events: all;
`

const WideMediaSection = styled(Section)`
  ${ ({ invertHeader }) => invertHeader ? `
    position: relative;
    z-index: 10;
  ` : `` }
  background: ${ colors.textColor };
`

const HeaderClip = styled.div`
  z-index: 2;
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
`

const Block = styled.div`
	display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  ${ ({ background }) => background ? `
    height: 100%;
    overflow: hidden;
    z-index: 1;
    ` : '' }
  height: ${ ({ setHeight }) => setHeight };
  ${ ({ content }) => content ? 'z-index: 3; position: absolute; top: 0;' : '' }
`

// const MediaImage = styled(Image)`
// 	${ ({ setHeight }) => setHeight !== 'auto' ? `
// 		> div {
// 			height: ${ setHeight };
// 			position: relative;
// 		}
// 		img {
// 			position: absolute;
// 			top: 0;
// 			left: 0;
// 			object-fit: cover;
// 			width: 100%;
// 			height: 100%;
// 		}
//   ` : `` }
// `

const MediaImage = styled(Image)`
  min-height: ${ ({ setHeight }) => setHeight };
`

const MediaVideo = styled(Video)`
	${ ({ setHeight }) => setHeight !== 'auto' ? `
		> div {
			height: ${ setHeight };
			min-height: 40vw;
			position: relative;
		}
		video {
			position: absolute;
			top: 0;
      left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	` : `` }
`

const SectionStyled = styled(Section)`
  width: 100%;
  position: relative;
  ${ ({ overlayTextColor }) => overlayTextColor ? `color: ${ colors[overlayTextColor] }` : '' }
`

const AlignmentContainer = styled.div`
  position: absolute;
  z-index: 11;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  text-align: ${ ({ overlayTextAlignment }) => overlayTextAlignment || 'left' };
  align-items: ${ ({ verticalPlacement }) => {
    if (!verticalPlacement) return 'center'
    if (verticalPlacement === 'top') return 'flex-start'
    if (verticalPlacement === 'bottom') return 'flex-end'
    else return verticalPlacement
  } };
  min-height: ${ ({ setHeight, windowHeight }) => setHeight === '100vh' ? (windowHeight || '100vh') : setHeight };
`

function getHorizontalPlacementGridValues ({ fullWidth, horizontalPlacement }) {
  if (!fullWidth) {
    return {
      left: {
        medium: '1 [5] 8',
        large: '1 [5] 8',
        larger: '1 [5] 8'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '8 [5] 1',
        large: '8 [5] 1',
        larger: '8 [5] 1'
      }
    }[horizontalPlacement]
  } else {
    return {
      left: {
        medium: '1 [5] 8',
        large: '1 [5] 8',
        larger: '1 [5] 8'
      },
      center: {
        medium: '2 [10] 2',
        large: '3 [8] 3',
        larger: '4 [6] 4'
      },
      right: {
        medium: '8 [5] 1',
        large: '8 [5] 1',
        larger: '8 [5] 1'
      }
    }[horizontalPlacement]
  }
}

class WideMedia extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      mobile: false
    }
  }

  componentDidMount () {
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.os() === 'iOS') {
      this.setState({ mobile: true })
    }
  }

  shouldComponentUpdate (prevProps, prevState) {
    const md = new MobileDetect(window.navigator.userAgent)
    if (md.os() === 'iOS' && prevProps.winHeight !== this.props.winHeight) {
      return false
    }

    return true
  }

  render () {
    const {
      width,
      theme,
      prevTheme,
      nextTheme,
      caption,
      height,
      overlayComponent,
      overlayPlacement,
      overlayTextAlignment,
      overlayTextColor,
      isFirstSection,
      winHeight,
      navigation,
      mobileMedia,
      clickToPlay
    } = this.props
    let { media } = this.props
    if (!media) {
      return false
    }

    const fullWidth = width === 'fullWidth'
    const type = media[0].__typename === 'ContentfulVideo' ? 'video' : 'image'
    media = media[0]

    const heightValues = {
      auto: 'auto',
      fullHeight: '100vh',
      mediumHeight: '70vh',
      shortHeight: '50vh'
    }

    const [verticalPlacement, horizontalPlacement] = (overlayPlacement || 'center center').split(' ')
    const overlayGridSettings = getHorizontalPlacementGridValues({ fullWidth, horizontalPlacement })

    const { mobile } = this.state
    let windowHeight = '100vh'
    if (mobile && this.props.index === 0) {
      windowHeight = winHeight + 'px'
    }

    return (
      <WideMediaSection
        setTheme={theme}
        nextTheme={nextTheme}
        prevTheme={prevTheme}
        padded={!fullWidth}
        overlayTextColor={overlayTextColor}
        invertHeader={fullWidth && isFirstSection}
      >
        {fullWidth && isFirstSection && (
          <HeaderClip>
            <StyledHeader setTheme="white" placeholder={false} navigation={navigation} />
          </HeaderClip>
        )}
        <Block background setHeight={heightValues[height]}>
          <Grid small={fullWidth ? '[1]' : '1 [12] 1'} large={fullWidth ? '[1]' : '1 [24] 1'}>
            <div style={{ position: 'relative' }}>
              {overlayComponent && (
                <AlignmentContainer
                  padded={!fullWidth}
                  overlayTextAlignment={overlayTextAlignment}
                  verticalPlacement={verticalPlacement}
                  setHeight={heightValues[height]}
                  windowHeight={windowHeight}
                  overlayTextColor={overlayTextColor}
                >
                  <SectionStyled overlayTextColor={overlayTextColor} padded>
                    <Grid small={fullWidth ? '1 [12] 1' : '2 [10] 2'} {...overlayGridSettings}>
                      <Column
                        items={overlayComponent.content}
                        type={overlayComponent.type}
                      />
                    </Grid>
                  </SectionStyled>
                </AlignmentContainer>
              )}
              {mobileMedia ? (
              <ResponsiveComponent
                small={mobileMedia.file.contentType.includes('video') ? (
                    <MediaVideo
                      video={mobileMedia}
                      playing={true}
                      loop={true}
                      muted={true}
                      setHeight={heightValues[height]}
                      autoplay={true}
                      light={clickToPlay === 'Click To Play'}
                    />
                  ) : (
                    <MediaImage
                      image={mobileMedia}
                      setHeight={heightValues[height]}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                    />
                  )}
                medium={<Fragment>
                  {type === 'image' ? (
                    <MediaImage
                      image={media.image}
                      small={media.small}
                      medium={media.medium}
                      alt={media.description || media.title}
                      setHeight={heightValues[height]}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                    />
                  ) : (
                    <MediaVideo
                      video={media.video}
                      playing={true}
                      loop={true}
                      muted={true}
                      setHeight={heightValues[height]}
                      posterImage={media.posterImage}
                      autoplay={true}
                      light={clickToPlay === 'Click To Play'}
                    />
                  )}
                </Fragment>}
              />
              ) : (
                <Fragment>
                  {type === 'image' ? (
                    <MediaImage
                      image={media.image}
                      small={media.small}
                      medium={media.medium}
                      alt={media.description || media.title}
                      setHeight={heightValues[height]}
                      loading={isFirstSection ? 'eager' : 'lazy'}
                    />
                  ) : (
                    <MediaVideo
                      video={media.video}
                      playing={true}
                      loop={true}
                      muted={true}
                      setHeight={heightValues[height]}
                      posterImage={media.posterImage}
                      autoplay={true}
                      light={clickToPlay === 'Click To Play' && media.posterImage}
                    />
                  )}
                </Fragment>
              )}
            </div>
          </Grid>
        </Block>
        {caption && (
          <Grid small="1 [12] 1">
            <div>
              <div>
                <Caption>{caption}</Caption>
              </div>
            </div>
          </Grid>
        )}
      </WideMediaSection>
    )
  }
}

WideMedia.defaultProps = {
  width: 'margins',
  height: 'auto'
}

const sizesToProps = ({ width, height }) => ({
  winWidth: width,
  winHeight: height
})

export default withSizes(sizesToProps)(WideMedia)
