import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import Logo from 'src/components/Logo'
import Section from 'src/components/Section'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import Collapse from 'src/components/Collapse'
import FooterNewsletterSignup from 'src/components/FooterNewsletterSignup'
import { mq, util, typography, animations } from 'src/styles'
import { footerThemes } from 'src/styles/themes'
import { MdKeyboardArrowDown } from 'react-icons/md'

const Wrapper = styled.div`
	position: relative;
	z-index: 2;
  h6 {
    margin-bottom: .8em;
    a {
      position: relative;
      &:hover {
        &:after {
          opacity: 1;
          transform: rotate(-45deg);
        }
      }
      &:after {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out;
        transform: rotate(-45deg) translate3d(-5px, -5px, 0);
        position: absolute;
        left: calc(100% + 5px);
        top: 1px;
        opacity: 0;
      }
    }
  }
  ${ ({ footerTheme }) => {
    const { background, color } = footerThemes[footerTheme || 'default']
    return `
      background: ${ background };
      color: ${ color };
      font-weight: 500;
    `
  } }
`

const FooterSection = styled(Section)`
  ${ util.responsiveStyles('padding-bottom', 100, 60, 50, 32) }
  ${ util.responsiveStyles('padding-top', 100, 60, 50, 32) }
`

const FooterLogo = styled(Logo)`
  margin-left: -4px;
	${ util.responsiveStyles('width', 180, 150, 150, 120) }
  ${ util.responsiveStyles('margin-bottom', 80, 50, 40, 30) }
`

const FooterCol = styled.div`
  ${ ({ lastItem }) => lastItem ? `
    ${ mq.smallAndBelow } {
      border-bottom: 1px solid currentcolor;
    }
  ` : `` }
  h6 {
    font-family: ${ typography.secondaryFont };
    font-weight: 900;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      a {
        font-weight: 500;
        position: relative;
        &:after {
          position: absolute;
          top: 1.3em;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          right: .075em;
          background: currentcolor;
          transform: scaleX(0);
          transform-origin: 100% center;
          transition: transform ${ animations.mediumSpeed } ease-in-out;
        }
        &:hover {
          &:after {
            color: currentcolor;
            transform-origin: 0% center;
            transform: scaleX(1);
          }
        }
      }
    }
  }
`

const FooterBottom = styled.div`
	position: relative;
  padding-top: 16px;
  ${ util.responsiveStyles('padding-bottom', 100, 60, 50, 32) }
  p {
    ${ util.responsiveStyles('font-size', 14, 13, 13, 12) }
    font-weight: 500;
  }
`

const Copyright = styled.div`
	display: flex;
	align-items: center;
  ${ mq.smallAndBelow } {
    display: none;
  }
	p {
		margin: 0;
		max-width: none;
		.mobile-hide {
			${ mq.mediumAndBelow } {
				display: none;
			}
		}
	}
`

const SmallLinks = styled.div`
	text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${ mq.mediumAndBelow } {
    justify-content: center;
  }
	a {
    ${ util.responsiveStyles('font-size', 14, 13, 13, 12) }
    font-weight: 500;
    position: relative;
    display: inline-block;
    ${ mq.mediumAndBelow } {
      padding: 5px 0;
    }
    &:not(:last-of-type) {
      ${ util.responsiveStyles('margin-right', 60, 40, 30, 20) }
    }
    &:after {
      position: absolute;
      top: 1.7em;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      right: .075em;
      background: currentcolor;
      transform: scaleX(0);
      transform-origin: 100% center;
      transition: transform ${ animations.mediumSpeed } ease-in-out;
    }
    &:hover {
      &:after {
        color: currentcolor;
        transform-origin: 0% center;
        transform: scaleX(1);
      }
    }
	}
`

const NewsletterContainer = styled.div`
`

const CollapsedNav = styled(Collapse)`
  ul {
    li {
      a {
        position: relative;
        ${ typography.body };
        font-weight: 500;
        display: inline-block;
        padding: 3px 0;
        &:after {
          top: unset !important;
          bottom: 2px;
        }
      }
      &:first-of-type {
        margin-top: -5px;
      }
    }
  }
`

const MobileNavHeader = styled.div`
  ${ typography.h6 }
  font-weight: 600;
  display: block;
  padding: 2px 0;
  color: inherit;
  display: flex;
  text-align: left;
  border-top: 1px solid currentcolor;
  height: 50px;
  align-items: center;
  span, a {
    flex-grow: 1;
    display: block;
  }
  svg {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
  }
`

const BottomFooterGrid = styled(Grid)`
  ${ mq.mediumAndBelow } {
    display: flex;
    justify-content: space-between;
  }
`

function Footer (props) {
  const { footerTheme, footerColumns, smallFooterLinks, footerNewsletterText } = props
  return (
    <Wrapper footerTheme={footerTheme}>
      <FooterSection>
        <Grid
          small="1 [12] 1"
          large="1 [24] 1"
        >
          <FooterLogo />
        </Grid>
        <Grid
          small="1 [12] 1"
          medium="1 [12] 1"
          large="1 [15] [8] 2"
          rowGap="40px"
        >
          <Grid
            small="[12]"
            medium="[1] [1] [1]"
          >
            {footerColumns.map((column, index) => (
              <FooterCol lastItem={index + 1 === footerColumns.length} key={column.link}>
                <ResponsiveComponent
                  small={<div>
                    <CollapsedNav
                      icon={false}
                      title={
                        <MobileNavHeader>
                          <Link to={column.to} external={column.linkType === 'external'}>{column.label}</Link>
                          <MdKeyboardArrowDown size="24px" />
                        </MobileNavHeader>
                      }
                    >
                      <ul style={{ paddingBottom: '18px' }}>
                        {column.dropdownLinks ? column.dropdownLinks.map((link, index) => (
                          <li key={link.id + link.to}>
                            <Link
                              key={link.id + link.to + 'mobile-footer-link'}
                              to={link.to}
                              external={link.linkType === 'external'}
                              target={link.linkType === 'external' ? '_blank' : ''}
                            >
                              {link.label}
                            </Link>
                          </li>
                        )) : ''}
                      </ul>
                    </CollapsedNav>
                  </div>}
                  mediumSmall={<div>
                    <h6><Link to={column.to} external={column.linkType === 'external'}>{column.label}</Link></h6>
                    <ul>
                      {column.dropdownLinks ? column.dropdownLinks.map((link, index) => (
                        <li key={link.id + link.to}>
                          <Link
                            key={link.id + link.to + 'desktop-footer-link'}
                            to={link.to}
                            external={link.linkType === 'external'}
                            target={link.linkType === 'external' ? '_blank' : ''}
                          >
                            {link.label}
                          </Link>
                        </li>
                      )) : ''}
                    </ul>
                  </div>}
                />
              </FooterCol>
            ))}
          </Grid>
          <NewsletterContainer>
            <h6>Updates</h6>
            <p style={{ marginBottom: 5, fontWeight: 500 }}>
              <div>
                {footerNewsletterText}
              </div>
            </p>
            <FooterNewsletterSignup footerTheme={footerTheme} />
          </NewsletterContainer>
        </Grid>
      </FooterSection>
      <FooterBottom>
        <Grid
          small="1 [12] 1"
          medium="1 [12] 1"
          large="1 [24] 1"
        >
          <BottomFooterGrid
            small="[1]"
            medium="[4] [8]"
            large="[5] [15] 4"
            vAlign="center"
          >
            <Copyright>
              <p className="sm">
                ©
                  <span className="mobile-hide">
                  &nbsp;YONY LLC
                    </span>
                    &nbsp;{new Date().getFullYear()}
              </p>
            </Copyright>
            <SmallLinks footerTheme={footerTheme}>
              {smallFooterLinks.map((link, index) => (
                <Link to={link.to} external={link.external} target={link.external ? '_blank' : '' }>
                  {link.label}
                </Link>
              ))}
            </SmallLinks>
          </BottomFooterGrid>
        </Grid>
      </FooterBottom>
    </Wrapper>
  )
}

export default Footer
