import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { withShopifyContext } from 'src/state/ShopifyState'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import Img from 'gatsby-image'
import Section from 'src/components/Section'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Slideshow from 'src/components/Slideshow'
import Button from 'src/components/Button'
import { colors, typography, animations, mq, util } from 'src/styles'
import { lookbookPreviewThemes } from 'src/styles/themes'
import Image from 'src/components/Image'
import { MdAdd } from 'react-icons/md'
import { Transition } from 'react-transition-group'
import { formatPrice } from 'src/utils/format'

const timeout = 300
const timingFunction = 'ease-in-out'
const overlayPadding = (attr = 'padding', buttonOffset = 0) => (util.responsiveStyles(attr, 40 + buttonOffset, 30 + buttonOffset, 20 + buttonOffset, 16 + buttonOffset))

const Container = styled.div`
  position: relative;
  user-select: none;
`

const MenuButton = styled(Button)`
  position: absolute;
  z-index: 7;
  width: 30px;
  min-width: 30px;
  height: 30px;
  svg {
    width: 24px;
    height: 24px;
  }
  &:hover {
    background: ${ colors.textColor };
    color: ${ colors.white };
    border-color: ${ colors.textColor };
    transform: rotate(90deg);
  }
  ${ ({ menuOpen }) => menuOpen ? `
    transform: rotate(135deg);
    &:hover {
      transform: rotate(135deg);
    }
  ` : `
    transform: none;
  ` }
`

const Menu = styled.div`
  ${ ({ menuOpen }) => menuOpen ? 'opacity: 1: pointer-events: unset;' : 'opacity: 0; pointer-events: none;' }
  position: absolute;
  z-index: 6;
  border: 1px solid black;
  display: flex;
  column-gap: 8px;
  align-items: stretch;
  background: white;
  padding: 8px;
  transition: opacity ${ timeout }ms ${ timingFunction }, transform ${ timeout }ms ${ timingFunction };
  transform: ${ ({ shopButtonPlacement }) => shopButtonPlacement.includes('Right') ? (
    `translate3d(12px, 0, 0)`
  ) : (
    `translate3d(-12px, 0, 0)`
  ) };
  opacity: 0;
  ${ ({ transitionStatus }) => transitionStatus === 'exited' ? `
    opacity: 0;
  ` : `` }
  ${ ({ transitionStatus }) => transitionStatus === 'entered' ? `
    transform: none;
    opacity: 1;
  ` : `` }
  max-width: calc(100% - 50px);
  overflow-x: auto;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 5;
  ${ ({ shopButtonPlacement }) => {
    if (!shopButtonPlacement || shopButtonPlacement === 'topRight') {
      return `
        ${ MenuButton } {
          ${ overlayPadding('top') }
          ${ overlayPadding('right') }
        }
        ${ Menu } {
          ${ overlayPadding('top') }
          ${ overlayPadding('right', 40) }
          ${ mq.mediumAndBelow } {
            ${ overlayPadding('top', 15) }
            ${ overlayPadding('right', 15) }
          }
        }
      `
    } else if (shopButtonPlacement === 'topLeft') {
      return `
        ${ MenuButton } {
          ${ overlayPadding('top') }
          ${ overlayPadding('left') }
        }
        ${ Menu } {
          ${ overlayPadding('top') }
          ${ overlayPadding('left', 40) }
          ${ mq.mediumAndBelow } {
            ${ overlayPadding('top', 15) }
            ${ overlayPadding('left', 15) }
          }
        }
      `
    } else if (shopButtonPlacement === 'bottomLeft') {
      return `
        ${ MenuButton } {
          ${ overlayPadding('bottom') }
          ${ overlayPadding('left') }
        }
        ${ Menu } {
          ${ overlayPadding('bottom') }
          ${ overlayPadding('left', 40) }
          ${ mq.mediumAndBelow } {
            ${ overlayPadding('bottom', 15) }
            ${ overlayPadding('left', 15) }
          }
        }
      `
    } else if (shopButtonPlacement === 'bottomRight') {
      return `
        ${ MenuButton } {
          ${ overlayPadding('bottom') }
          ${ overlayPadding('right') }
        }
        ${ Menu } {
          ${ overlayPadding('bottom') }
          ${ overlayPadding('right', 40) }
          ${ mq.mediumAndBelow } {
            ${ overlayPadding('bottom', 15) }
            ${ overlayPadding('right', 15) }
          }
        }
      `
    }
  } }
`

const ProductCard = styled.div`
  width: 120px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
`

const ProductInfo = styled.div`
  margin-top: 5px;
  margin-bottom: 8px;
  flex-grow: 1;
  flex-shrink: 0;
`
const ProductPrice = styled.div`
  ${ typography.bodySmall }
  ${ util.responsiveStyles('font-size', 12, 11, 11, 10) }
  font-weight: 500;
  color: ${ colors.lightTextColor };
`

const ShopButton = styled(Button)`
  padding-left: 1em;
  padding-right: 1em;
  display: block;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
`

const ThumbnailImage = styled(Img)`
  flex-grow: 0;
  flex-shrink: 0;
`

const ProductTitle = styled.div`
  ${ typography.bodySmall }
  ${ util.responsiveStyles('font-size', 12, 11, 11, 10) }
  font-weight: 500;
`

class ShoppableImage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      products: null,
      menuOpen: false
    }
  }
  componentDidMount () {
    const { productHandles, shopifyContext } = this.props
    const { fetchProductByHandle } = shopifyContext
    if (productHandles && productHandles.length > 0) {
      const promises = productHandles.map(productHandle => fetchProductByHandle({ productHandle }))
      Promise.all(promises)
        .then(products => { if (products) this.setState({ products }) })
    }
  }

  handleOverlayClick = () => {
    if (this.state.menuOpen) {
      this.setState({ menuOpen: false })
    }
  }

  render () {
    const {
      id,
      image,
      imageWidth,
      shopButtonPlacement,
    } = this.props

    const getResizedImage = (image, size = 1200) => {
      const { resizeShopImage } = this.props.shopifyContext
      return resizeShopImage(image, { maxWidth: size, maxHeight: size })
    }

    const { menuOpen, products } = this.state
    return (
      <Grid
        small="[14]"
        medium={imageWidth === 'fullWidth' ? '[12]' : '1 [12] 1'}
        large={imageWidth === 'fullWidth' ? '[24]' : '1 [24] 1'}
      >
        <Container>
          <Image {...image} />
          {products && (
            <Overlay shopButtonPlacement={shopButtonPlacement}>
              <MenuButton
                shape='circle'
                size='small'
                setTheme='blackSolid'
                menuOpen={menuOpen}
                onClick={() => this.setState(prevState => ({ menuOpen: !prevState.menuOpen }))}
                icon={<MdAdd/>}
              ></MenuButton>
                <Transition
                  in={menuOpen}
                  timeout={{
                    enter: 1,
                    exit: timeout
                  }}
                >
                  {transitionStatus => (
                    <Menu
                      shopButtonPlacement={shopButtonPlacement}
                      menuOpen={menuOpen}
                      transitionStatus={transitionStatus}
                    >
                      {products.map(product => {
                        if (!product) return ''
                        const { images = [] } = product
                        if (!images) {
                          return ''
                        } else {
                          return (
                            <ProductCard>
                              <ThumbnailImage
                                fluid={{
                                  aspectRatio: 0.75,
                                  src: getResizedImage(images[0], 250),
                                  srcSet: '',
                                  sizes: ''
                                }}
                              />
                              <ProductInfo>
                                <ProductTitle><div>{product.title}</div></ProductTitle>
                                <ProductPrice>{formatPrice(product.variants[0].price.amount)}</ProductPrice>
                              </ProductInfo>
                              <ShopButton
                                size='tiny'
                                setTheme="blackSolid2"
                                to={`/shop/${ product.handle }`}
                                label='SHOP NOW'
                              />
                            </ProductCard>
                          )
                        }
                      })}
                    </Menu>
                  )}
                </Transition>
            </Overlay>
          )}
        </Container>
      </Grid>
    )
  }
}

export default withShopifyContext(ShoppableImage)
