import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import { withShopifyContext } from 'src/state/ShopifyState'
import Link from 'src/components/Link'
import Logo from 'src/components/Logo'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import ConditionalRender from 'src/components/ConditionalRender'
import ScrollListener from 'src/components/ScrollListener'
import NotificationBanner from 'src/components/NotificationBanner'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import AnimatedIcon from 'src/components/AnimatedIcon'
import ContentfulRichText from 'src/components/ContentfulRichText'
import Button from 'src/components/Button'
import { colors, typography, animations, mq, util } from 'src/styles'
import { lookbookPreviewThemes } from 'src/styles/themes'
import Image from 'src/components/Image'

const Colors = styled.div`
  ${ ({ previewColor }) => `
    color: ${ lookbookPreviewThemes[previewColor].color };
    background: ${ lookbookPreviewThemes[previewColor].backgroundColor };
    button {
      color: ${ lookbookPreviewThemes[previewColor].color };
      border-color: ${ lookbookPreviewThemes[previewColor].color };
      background: transparent;
    }
  ` }
`

const H2 = styled.div`
  ${ typography.h2 }
`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const TextSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${ mq.smallAndBelow } {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export default ({
  id,
  displayTitle,
  slug,
  previewImage,
  previewColor,
  contentAlignment,
  prevTheme,
  nextTheme,
  padded
}) => {
  const buttonTheme = {
    red: 'yellow',
    pink: 'pink',
    offwhite: 'pink',
    white: 'black',
    yellow: 'pink',
    green: 'offwhite'
  }[previewColor]
  return (
    <Colors
      previewColor={previewColor}
    >
      <Section
        nextTheme={nextTheme}
        prevTheme={prevTheme}
        padded={padded}
      >
        <Grid
          small='[14]'
          medium='2 [15] 1 [6] 2'
          large='1 [16] 1 [7] 1'
          gridDirection={contentAlignment === 'Image Left' ? 'ltr' : 'rtl'}
        >
          <div>
            {previewImage && (
              <Image {...previewImage} />
            )}
          </div>
          <TextSection contentAlignment={contentAlignment}>
            <Grid
              small="1 [12] 1"
              medium="[1]"
              large="[1]"
              colGap="16px"
              rowGap="16px"
            >
              <div>
                <H2>{displayTitle}</H2>
              </div>
              <div>
                <Button
                  setTheme={buttonTheme}
                  label='READ MORE'
                  to={'/lookbook/' + slug}
                />
              </div>
            </Grid>
          </TextSection>
        </Grid>
      </Section>
    </Colors>
  )
}
