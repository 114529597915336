import React from 'react'
import styled from '@emotion/styled'
import Img from 'gatsby-image/withIEPolyfill'
import { mq } from 'src/styles'

const SvgWrap = styled.div`
	display: inline-block;
	vertical-align: top;
	svg {
		display: block;
		width: 100%;
		height: 100%;
	}
`

const StyledImage = styled(Img)`
${ ({ height }) => height ? `height: ${ height };` : '' }
	${ ({ small, medium }) => `
		> div {
			${ medium ? `
				${ mq.largeAndBelow } {
					padding-bottom: ${ 100.0 / medium.aspectRatio }% !important;
				}
			` : `` }
			${ small ? `
				${ mq.mediumAndBelow } {
					padding-bottom: ${ 100.0 / small.aspectRatio }% !important;
				}
			` : `` }
		}
	` }
	img {
		transition: opacity 1s ease-in-out !important;
	}
`

const ResponsiveImage = ({ image, small, medium, large, height, className, loading, customSizes, critical }) => {
  if (!(image && image.fluid) && !small && !medium && !large) return ''
  if (small || medium || large || image) {
    let source = []

    if (image && image.fluid) {
      image.fluid.sizes = customSizes
      source.push({
        ...image.fluid,
        media: `(min-width: ${ mq.largeBreakpoint + 1 }px)`,
      })
    }

    if (medium && medium.fluid) {
      medium.fluid.sizes = customSizes
      source.push({
        ...medium.fluid,
        media: `(min-width: ${ mq.mediumBreakpoint + 1 }px)`,
      })
    }

    if (small && small.fluid) {
      small.fluid.sizes = customSizes
      source.push({
        ...small.fluid,
        media: `(min-width: 1px)`,
      })
    }

    return (
      <StyledImage
        className={className}
        fluid={source}
        small={small}
        medium={medium}
        placeholderStyle={{ display: 'none' }}
        durationFadeIn={1000}
        loading={loading}
        critical={critical}
        height={height}
      />
    )
  } else {
    return false
  }
}

const Image = ({ image, height, small, medium, className, sizes = '100vw', loading, maxWidth, style, critical }) => image && (
  <div className="image-wrapper" style={{
    width: '100%',
    maxWidth: maxWidth || '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    height: height || '',
  }}>
    {image && image.svgContent ? (
      <SvgWrap className={className} dangerouslySetInnerHTML={{ __html: image.svgContent }} />
    ) : (
        <ResponsiveImage
          image={image}
          small={small}
          medium={medium}
          className={className}
          customSizes={sizes}
          loading={loading}
          critical={critical}
          height={height}
        />
      )}
  </div>
)

Image.defaultProps = {
  loading: 'lazy',
  style: 'default',
  critical: false
}

export {
  ResponsiveImage,
  Image as default
}
