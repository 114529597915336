import React, { Component, useState } from 'react'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import Button from 'src/components/Button'

const Wrapper = styled.div`
	${ ({ posterImage, ratio, soundControls }) => posterImage && `
		position: relative;
		padding-bottom: ${ 100 / (ratio || posterImage.fluid.aspectRatio) }%;
	` }
	${ ({ soundControls }) => soundControls && `
		position: relative;
	` }
	video {
		display: block;
		margin: 0;
		outline: none;
		&:focus {
			outline: none;
		}
	}
`

const VideoWrapper = styled.div`
	${ ({ posterImage }) => posterImage && `
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	` }
	${ ({ soundControls }) => soundControls && `
		position: relative;
		z-index: 1;
	` }
	${ ({ cover }) => cover && `
		height: 100%;
	` }
`

const StyledVideo = styled(ReactPlayer)`
	z-index: 10;
	position: relative;
	min-width: 100%;
	min-height: 100%;
	width: 100% !important;
	height: auto !important;
	> div {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	${ ({ cover }) => cover && `
		video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;
		}
	` }
	${ ({ url, cover }) => url.indexOf('youtube') > -1 || url.indexOf('vimeo') > -1 ? `
		padding-bottom: ${ 9 / 16 * 100 }%;
		${ cover && `
			position: absolute;
			top: 50%;
			left: 50%;
			padding: 0;
			width: 100% !important;
			height: 100% !important;
			@media (min-aspect-ratio: 16/9) {
				// tall
				min-width: 100vw;
				min-height: 56.25vw;
				margin-left: -50vw;
				margin-top: -28.125vw;
			}
			@media (max-aspect-ratio: 16/9) {
				// wide
				min-width: 177.77vh;
				min-height: 100vh;
				margin-left: -88.885vh;
				margin-top: -50vh;
			}
			min-width: 0;
			min-height: 0;
		` }
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	` : `
	` }
`

const SoundButton = styled(Button)`
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 3;
`


const Video = ({
	video,
	loop,
	cover,
	muted,
	autoplay,
	className,
	playing,
	light,
	posterImage,
	soundControls
}) => {
	const [isMuted, setIsMuted] = useState(true)

	const soundToggle = () => {
		setIsMuted(!isMuted)
	}

	if (!video || !video.file || !video.file.url) {
		return false
	}
	return (
		<Wrapper className={className} cover={cover} posterImage={posterImage} ratio={1.777} soundControls={soundControls}>
			<VideoWrapper cover={cover} posterImage={posterImage} soundControls={soundControls}>
				<StyledVideo
					cover={cover}
					url={'https:' + video.file.url}
					playing={playing}
					loop={light ? false : loop}
					muted={light ? false : (soundControls ? isMuted : muted)}
					autoPlay={autoplay}
					playsinline={true}
					controls={light}
					light={light && posterImage && 'http:' + posterImage.fluid.src}
				/>
			</VideoWrapper>
			{soundControls && (
				<SoundButton icon={isMuted ? 'volume_up' : 'volume_off'} shape='circle' setTheme="blackSolid" onClick={soundToggle} />
			)}
		</Wrapper>
	)
}

Video.defaultProps = {
	playing: false,
	loop: true,
	muted: true
}

export default Video
