import React from 'react'
import styled from '@emotion/styled'
import Image from 'src/components/Image'
import Grid from 'src/components/Grid'
import Section from 'src/components/Section'
import { mq } from 'src/styles'

const ImageInBlog = props => {
  return (
    <Section
      setTheme={props.theme || 'white'}
      nextTheme={props.nextTheme}
      prevTheme={props.prevTheme}
      padded={props.padded}
    >
      <Grid
        small="1 [12] 1"
        medium="1 [12] 1"
        large="7 [12] 7"
      >
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Image {...props} />
        </div>
      </Grid>
    </Section>
  )
}
export default ImageInBlog
