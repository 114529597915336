import React, { Component, Fragment } from 'react'
import styled from '@emotion/styled'

import * as util from 'src/styles/util'
import { colors, typography, animations } from 'src/styles'
import MaterialIcon from 'src/components/MaterialIcon'

import Link from 'src/components/Link'

const TextLinkStyle = () => `
	${ typography.h6 }
	position: relative;
	display: inline-block;
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2px;
		background: currentcolor;
		opacity: .5;
	}
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 2px;
		background: ${ ({ theme }) => colors[theme] };
		transform: scaleX(0);
		transform-origin: right center;
		transition: transform ${ animations.mediumSpeed } ease-in-out;
	}
	&:hover {
		&:after {
			transform-origin: left center;
			transform: scaleX(1);
		}
	}
`

const StyledLink = styled(Link)`${ TextLinkStyle() }`
const StyledLinkElement = styled.a`${ TextLinkStyle() }`

class TextLink extends Component {
	render () {
		const {
			to,
			onClick,
			external,
			target,
			loading,
			error,
			success,
			disabled,
			theme,
			className,
			children,
			label,
			title,
			name
		} = this.props
		if (onClick) {
			return (
				<StyledLinkElement
					className={'button ' + className}
					onClick={onClick}
					target={target}
					external={external}
					loading={loading}
					error={error}
					success={success}
					disabled={disabled}
					theme={theme}
					label={label}
					title={label || title || name}
					name={label || title || name}
				>
					{children}
				</StyledLinkElement>
			)
		} else {
			return (
				<StyledLink
					className={'button ' + className}
					to={to}
					target={target}
					external={external}
					loading={loading}
					error={error}
					success={success}
					disabled={disabled}
					theme={theme}
					label={label}
					title={label || title || name}
					name={label || title || name}
				>
					{children}
				</StyledLink>
			)
		}
	}
}

TextLink.defaultProps = {
	theme: 'mainColor'
}

export default TextLink
