import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Column from 'src/components/Column'
import { withShopifyContext } from 'src/state/ShopifyState'
import { colors, typography, animations, mq, util } from 'src/styles'
import ProductPreview from 'src/components/ProductPreview'
import Image from 'src/components/Image'

const ProductThumbnail = styled(ProductPreview)`
  ${ ({ lastItem }) => !lastItem ? `
    ${ mq.mediumAndUp } {
      ${ util.responsiveStyles('margin-bottom', 88, 55, 30, 20) }
    }
  ` : `` }
`

const CollectionWrapper = styled.div`
  justify-self: center;
  ${ mq.smallAndBelow } {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
    padding-left: ${ 100 / 14 }vw;
    padding-bottom: 14px;
    ${ ProductThumbnail } {
      width: 40vw;
      flex-shrink: 0;
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-right: 14px;
      }
      &:last-of-type {
        width: calc(40vw + ${ 100 / 14 }vw);
        padding-right: ${ 100 / 14 }vw;
      }
    }
  }
`

const SectionStyled = styled(Section)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ mq.smallAndBelow } {
    ${ ({ noPaddingMobile }) => noPaddingMobile ? 'padding-top: 0;' : '' }
  }
`

const StickyContainer = styled(Grid)`
  position: relative;
  height: 100%;
  width: 100%;
`

const Sticky = styled.div`
  // Use header height here for top padding or none?
  ${ util.responsiveStyles('padding-top', 100, 56, 52, 52) }
  // ${ util.responsiveStyles('padding-top', 140, 96, 96, 60) }
  ${ util.responsiveStyles('padding-bottom', 100, 56, 52, 52) }
  position: sticky;
  top: 0;
  width: 100%;
  ${ mq.mediumAndUp } {
    height: 100vh;
    min-height: 36vw;
    > div,
    > div > div,
    > div > div > div {
      height: 100%;
    }
    .gatsby-image-wrapper {
      height: 100%;
      background: ${ colors.lightGrey };
    }
  }
`
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: ${ ({ cardAlignment }) => cardAlignment === 'right' ? 'flex-end' : 'flex-start' };
`

const Overlay = styled.div`
  text-align: center;
  padding-top: 26px;
  ${ mq.mediumAndUp } {
    padding-top: 0;
    color: ${ colors.offwhite };
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .button {
    ${ mq.smallAndBelow } {
      .button-content {
        color: currentcolor;
      }
      border-color: currentcolor;
      color: currentcolor;
      &:hover {
        background: ${ colors.textColor };
        border-color: ${ colors.textColor };
        color: ${ colors.bgColor };
      }
    }
  }
`

const OtherColumn = styled.div`
  width: 100%;
  ${ util.responsiveStyles('padding-bottom', 100, 56, 52, 52) }
  ${ mq.mediumAndUp } {
    ${ util.responsiveStyles('padding-top', 100, 56, 52, 52) }
  }
`

const FeaturedCollection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  isFirstSection,
  shopifyContext,
  collectionHandle,
  cardAlignment,
  cardImage,
  cardOverlay

}) => {
  const { collections } = shopifyContext
  const thisCollection = collections.find(x => x.handle === collectionHandle)
  const gridDirection = cardAlignment === 'right' ? 'rtl' : 'ltr'
  if (!thisCollection) return ''

  return (
    <SectionStyled
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
      padded={false}
    >
      <Grid
        small='[1]'
        medium='1 [7] 1 [4] 1'
        large='1 [12] 3 [7] 3'
        gridDirection={gridDirection}
      >
        <StickyContainer small="1 [12] 1" medium="[1]">
          <Sticky>
            <div>
              {(cardImage && cardImage.image) && <ImageWrapper cardAlignment={cardAlignment}>
                <Image
                  image={cardImage.image}
                  small={cardImage.small}
                  medium={cardImage.medium}
                  sizes={'(max-width: ' + mq.mediumBreakpoint + 'px) 86vw, 60vw'}
                  alt={cardImage.description || cardImage.title}
                  loading={isFirstSection ? 'eager' : 'lazy'}
                  fullWidth
                />
                {cardOverlay && (
                  <Overlay>
                    <Grid
                      small="[1]"
                      medium="1 [10] 1"
                      large="2 [10] 2"
                    >
                      <Column
                        items={cardOverlay.content}
                        type={cardOverlay.type}
                      />
                    </Grid>
                  </Overlay>
                )}
              </ImageWrapper>}
            </div>
          </Sticky>
        </StickyContainer>
        <OtherColumn>
          <CollectionWrapper>
            {(thisCollection && thisCollection.products)
              ? thisCollection.products
                .map((product, index) => (
                  <ProductThumbnail
                    key={`${ thisCollection.id }_${ product.id }_${ index }`}
                    product={product}
                    lastItem={index + 1 === thisCollection.products.length}
                  />
                ))
              : ''}
          </CollectionWrapper>
        </OtherColumn>
      </Grid>
    </SectionStyled>
  )
}

export default withShopifyContext(FeaturedCollection)
