import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Img from 'gatsby-image'
import Video from 'src/components/Video'
import TextLockup from 'src/components/TextLockup'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import { mq, util } from 'src/styles'
import { headerHeight } from 'src/components/Header'

const FFSection = styled(Section)``

const ColumnWrapper = styled.div``

const StyledText = styled(TextLockup)`
  ${ ({ sticky }) => sticky ? `
    position: sticky;
    ${ util.responsiveStyles('top', 140, 96, 96, 60) }
  ` : `` }
`

const FFImg = styled(Img)`
  img {
    transition: opacity 1s ease-in-out !important;
  }
`

const arrangeMedia = {
  left: {
    gridSetup: {
      normal: '2 [23] 2 [23] 2',
      large: '2 [25] 2 [21] 2',
      extraLarge: '2 [27] 2 [19] 2'
    }
  },
  right: {
    gridSetup: {
      normal: '2 [23] 2 [23] 2',
      large: '2 [25] 2 [21] 2',
      extraLarge: '2 [27] 2 [19] 2'
    }
  },
  bleedLeft: {
    gridSetup: {
      normal: '[27] 2 [23] 2',
      large: '[14] 1 [10] 1',
      extraLarge: '[15] 1 [9] 1'
    }
  },
  bleedRight: {
    gridSetup: {
      normal: '[27] 2 [23] 2',
      large: '[14] 1 [10] 1',
      extraLarge: '[15] 1 [9] 1'
    }
  }
}

const FiftyFifty = ({
  className,
  theme,
  prevTheme,
  nextTheme,
  ffmedia,
  mediaPlacement,
  mediaWidth,
  eyebrow,
  text,
  actions,
  paragraphSize,
  verticalAlignment,
  isFirstSection,
}) => {
  if (mediaPlacement === null) {
    mediaPlacement = 'left'
  }
  if (mediaWidth === null) {
    mediaWidth = 'normal'
  }
  let sticky = false
  if (verticalAlignment === 'sticky') {
    verticalAlignment = 'stretch'
    sticky = true
  }
  const media = ffmedia
  if (media && media.fluid) {
    media.fluid.sizes = '(max-width: ' + mq.mediumBreakpoint + 'px) 86vw, 55vw'
  }

  return (
    <FFSection
      className={className}
      setTheme={theme}
      prevTheme={prevTheme}
      nextTheme={nextTheme}
      isFirstSection={isFirstSection}
    >
      <Grid
        small="1 [12] 1"
        large={arrangeMedia[mediaPlacement].gridSetup[mediaWidth]}
        rowGap={['7vw', '7vw', '80px']}
        vAlign={verticalAlignment}
        gridDirection={mediaPlacement.includes('right') || mediaPlacement.includes('Right') ? 'rtl' : 'ltr'}
      >
        <ColumnWrapper>
          {media && (
            media.file && media.file.contentType === 'video/mp4' ? (
              <ScrollEntrance delay={0 /* TBD */}>
                <Video
                  video={media}
                  playing={true}
                  loop={true}
                  autoplay={true}
                  soundControls={true}
                />
              </ScrollEntrance>
            ) : (
              <ScrollEntrance delay={0 /* TBD */}>
                <FFImg
                  fluid={media && media.fluid}
                  loading={isFirstSection ? 'eager' : 'lazy'}
                />
              </ScrollEntrance>
            )
          )}
        </ColumnWrapper>

        <ColumnWrapper>
          <StyledText
            sticky={sticky}
            entranceDelay={1}
            eyebrow={eyebrow}
            text={text}
            textSize={paragraphSize}
            buttons={actions}
          />
        </ColumnWrapper>
      </Grid>
    </FFSection>
  )
}

FiftyFifty.defaultProps = {
  mediaWidth: 'normal',
  mediaPlacement: 'left'
}

export default FiftyFifty
