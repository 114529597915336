import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import TextLockup from 'src/components/TextLockup'
import Grid from 'src/components/Grid'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Accordion from 'src/components/Accordion'
import { util } from 'src/styles'

const Wrapper = styled(Section)`
  ${ ({ alignment }) => alignment !== 'right' ? `
    text-align: ${ alignment };
  ` : `` }
`

const ContentWrapper = styled.div`
  ${ util.responsiveStyles('padding-top', 50, 28, 33, 26) }
  ${ util.responsiveStyles('padding-bottom', 50, 28, 33, 26) }
`

const TextSection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  eyebrow,
  sectionText,
  headlineStyle,
  paragraphSize,
  alignment,
  isFirstSection,
  actions,
  accordion
}) => {
  if (!sectionText) {
    return false
  }

  const align = {
    // same as in WideMedia.jsx
    left: {
      medium: '1 [9] 4',
      large: '1 [9] 4',
      larger: '1 [9] 4'
    },
    center: {
      medium: '2 [10] 2',
      large: '3 [8] 3',
      larger: '4 [6] 4'
    },
    right: {
      medium: '7 [6] 1',
      large: '7 [6] 1',
      larger: '7 [5] 2'
    }
  }

  if (!alignment || alignment === null) {
    alignment = 'left'
  }

  const emptyText = sectionText.json.content[0].content[0].value === ''

  return (
    <Wrapper
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      alignment={alignment}
      isFirstSection={isFirstSection}
    >
      <ContentWrapper>
        <Grid
          small="1 [12] 1"
          medium={align[alignment].medium}
          large={align[alignment].large}
          larger={align[alignment].larger}
          rowGap="30px"
        >
          {(eyebrow || !emptyText || actions) && (
            <TextLockup
              eyebrow={eyebrow}
              text={sectionText}
              headlineStyle={headlineStyle}
              textSize={paragraphSize}
              buttons={actions}
            />
          )}
          {accordion && (
            <div>
              <Accordion items={accordion.items} textSize={paragraphSize} />
            </div>
          )}
        </Grid>
      </ContentWrapper>
    </Wrapper>
  )
}

export default TextSection
